/* foundation reveal settings
/// Default background color of a modal.
/// @type Color
$reveal-background: $white !default;

/// Default width of a modal, with no class applied.
/// @type Number
$reveal-width: 600px !default;

/// Default maximum width of a modal.
/// @type Number
$reveal-max-width: $global-width !default;

/// Default padding inside a modal.
/// @type Number
$reveal-padding: $global-padding !default;

/// Default border around a modal.
/// @type Number
$reveal-border: 1px solid $medium-gray !default;

/// Default radius for modal.
/// @type Number
$reveal-radius: $global-radius !default;

/// z-index for modals. The overlay uses this value, while the modal itself uses this value plus one.
/// @type Number
$reveal-zindex: 1005 !default;

/// Background color of modal overlays.
/// @type Color
$reveal-overlay-background: rgba($black, 0.45) !default;
*/


.reveal,
.reveal-overlay {
	z-index: 99999;
}
.reveal-overlay {
	overflow: hidden;
	background-color: rgba(10, 10, 10, 0.875);
}
.reveal,
.wm-window {

	//@include contentBox;
	@include transparentPanel;
	
	width: 87.5%;
	max-height: 90%;
	
	.wm-window-title,
	.wm-content {
		@include contentBox;
		background-color: rgba(0, 0, 0, 0.9);
		margin: 0.125em;
	}
	
	&.move {
		animation: none !important;
	}
	
	&.closed .wm-content {
		display: none;
	}
	
	&.inactive {
	    opacity: 0.5;
	    
		.wm-window-title,
		.wm-content {
			margin: 0em;
		}
	
	}
	
	.wm-window-box {
	
		header.wm-window-title { 
			@include contentBox;
		
			background-color: rgba(127, 191, 255, 0.3);
			
			h1 { 
				font-weight: bold;
			}
			
			button {
				width: 17px;
				height: 17px;
				margin-left: 7px;
				
				&.wm-minimize,
				&.wm-maximize,
				&.wm-close {
					background-position: center center;
				}
				
			}
		}
	
		button.wm-resize {
	    	width: 1.5em;
	    	height: 1.5em;
	    	bottom: 0;
	    	right: 0;
		    border-top-left-radius: 0.75em;
		    border-top-right-radius: 0em;
		    border-bottom-right-radius: 0em;
		    border-bottom-left-radius: 0em;
		    background-color: transparent;
		    background-position: 0.25em 0.25em;
		    box-shadow: none;
		}
		
	}
}

.wm-window-overlay {

	.inactive  & {
	    opacity: 0.75;
	    background-color: #000;
	    display: block;
	    position: absolute;
	    top: 0px;
	    left: 0px;
	    width: 100%;
	    height: 100%;
	    border-radius: 1em;
	}
}

.wm-window-title {
	opacity: 0.75;
}

/*.wm-window.move {
	animation: wobbly 0.5s 0.2s infinite;

	* {
		user-select: none;
	}
}*/

$close-icon-size: 2.1em;
$close-icon-size-rad: $close-icon-size * 0.75;
$close-icon-fontsize: $close-icon-size-rad * 0.66;
$close-icon-lineheight: 1.5;

.cta-xhr-modal-close {
	display        : block;
    position       : absolute;
    left           : 0.5rem;
    top            : 0.5rem;
    
    color          : $white;
    font-size      : $close-icon-size;
    font-weight    : bold;
    cursor         : pointer;
    
	border         : solid 1px _palette(fg);
	font-size      : $close-icon-fontsize * 1.1;
	height         : $close-icon-size-rad;
	line-height    : $close-icon-fontsize * $close-icon-lineheight;
	width          : $close-icon-size-rad;
	text-align     : center;
	
	@include contentBoxShadowOff;
	border-radius  : 100%;

	
	> span {
	    height         : $close-icon-size-rad;
	    line-height    : $close-icon-fontsize * $close-icon-lineheight;
	    width          : $close-icon-size-rad;
		font-size      : $close-icon-fontsize;
	}
	
	:hover {
	    
		background-color: _palette(nav-icon, hover-bg);
		color          : _palette(nav-icon, hover-fg);
		font-size      : $close-icon-fontsize;

		height         : $close-icon-size-rad;
		line-height    : $close-icon-fontsize * $close-icon-lineheight;
		width          : $close-icon-size-rad;
		
		@include contentBox;
		display        : block;
		border-radius  : 100%;
		
	
		> span {
		    height         : $close-icon-size-rad;
		    line-height    : $close-icon-fontsize * $close-icon-lineheight;
		    width          : $close-icon-size-rad;
			font-size      : $close-icon-fontsize;
		}
	
	}
	
	.wm-window & {
	    display: none;
	}
	
}