hr,
.callout {
    @include contentBox;
}

.callout {

    color: $white;

	&.primary {
	    @include contentBox;
        color: lightblue;

	}

	&.secondary {
        color: $black;

        p {
            color: $black;
        }
	}

	&.warning {
        color: orange;

        p {
            color: orange;
        }
	}

	&.alert {
        color: red;

        p {
            color: red;
        }
	}

	&.success {
        color: green;

        p {
            color: green;
        }
	}
}

header {

    &.row {
    	margin-left: 1em;
    }

}

/* code blocks */
code {
    background: lightgray;
    display: block;
    overflow: auto;
    font-family: monospace;
    line-height: 1.1em;
    font-size: 0.9em;
    padding: 0.5em;
    margin-bottom: 1em;
}

.ss-code {
	font-family: 'Courier New', serif;
	line-height: 1em;
}

/* screen panels */

.modalpopup {

	@include vendor('animation', 'modalpopup #{_duration(modalpopup)} #{$delay-modalpopup} forwards');
	@include vendor('backface-visibility', 'hidden');
	@include vendor('transform', 'translate3d(0,0,0)');


}
$panelscale: 4em;

.screenpanel,
[data-earththeme-screenpanel] {

	@include contentBox;
	display: block;
	/*
	width: calc(80vw - 0px);
	height: calc(77.5vh - 0px);
  */
  width: calc(87.5vw - 0px);
  height: calc(82.5vh - 0px);

	@include breakpoint(mobile) {
 	    width: calc(80vw - 0px);
	    height: calc(77.5vh - 0px);
	}

	@include breakpoint(mobilep) {
 	    width: calc(70vw - 0px);
	    height: calc(72.5vh - 0px);
	}

	h1 {
		font-size: 4.35em;
		font-weight: 900;
		letter-spacing: -0.035em;
		line-height: 1em;
	}

	p {
		font-size: 1.25em;
		margin: 0.75em 0 0.25em 0;
		opacity: 0.75;
	}

    .screenpanel-header,
    .screenpanel-body,
    .screenpanel-footer,
    [data-earththeme-screenpanel-header],
    [data-earththeme-screenpanel-body],
    [data-earththeme-screenpanel-footer] {
        position: absolute;
        left: 0;
        right: 0;
	}

    .screenpanel-header,
    .screenpanel-footer,
    [data-earththeme-screenpanel-header],
    [data-earththeme-screenpanel-footer] {
	    border: 1px solid rgba(127, 191, 255, 0.1);
	    box-shadow: 0px 0px 1px 0px rgba(127, 191, 255, 0.1);
	    background-color: #365d98;
	    background: rgba(127, 191, 255, 0.2);

	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-orient: horizontal;
	    -webkit-box-direction: normal;
	    -ms-flex-direction: row;
	    flex-direction: row;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;

	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;

	    border: 0;
	    padding: 0 8px;
	    overflow: hidden;

	    .wm-button-group {

		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-orient: horizontal;
		    -webkit-box-direction: normal;
		    -ms-flex-direction: row;
		    flex-direction: row;
		    -webkit-box-align: center;
		    -ms-flex-align: center;
		    align-items: center;

		    padding-left: 2px;

		    position: absolute;
		    top: 1em;
		    right: 1em;
		    left: auto;
		    bottom: auto;
		}

		button {

		    display: inline-block;
		    border: 0;
		    background-position: center center;
		    background-repeat: no-repeat;
		    background-color: #365d98;
		    background-color: rgba(127, 191, 255, 0.01);
		    color: rgba(127, 191, 255, 0.75); // $white;
		    margin: 0;
		    padding: 0;
		    width: 17px;
		    height: 17px;
		    margin-left: 7px;
		    opacity: .7;
            font-size: 1.125em;

		    &:hover {
		        box-shadow: 0px 0px 6px 4px rgba(127, 191, 255, 0.5);
		    }

		    &:disabled,
		    &:disabled:hover {
		        box-shadow: 0px 0px 4px 2px rgba(127, 127, 127, 0.5);
		        color: gray; //rgba(127, 127, 127, 0.5);
		    }

			&.wm-next .icon:before {
			    position: relative;
                left: 1px;
                top: -1px;
			}
			&.wm-previous .icon:before {
			    position: relative;
                left: -1.5px;
                top: -1px;
			}
			&.wm-close {
                font-size: 0.8em;
			}
			&.wm-maximize {
                font-size: 0.8em;
			}
			&.wm-minimize {
                font-size: 0.8em;
			}

		}
	}

  .screenpanel-header,
  [data-earththeme-screenpanel-header] {
	    border-radius: 1em 1em 0em 0em;

	    top: 0;
	    bottom: auto;
	    height: 3em;
	    min-height: 3em;

	    h2 {
		    font-size: 1.125em;
		    margin-bottom: 0;
		}
	}

  .screenpanel-body,
  [data-earththeme-screenpanel-body] {
	    top: 2em;
	    bottom: 2em;
	    height: auto;
	    min-height: auto;

		  overflow-x: hidden;
		  overflow-y: hidden;
	    background: transparent; // rgba(0, 0, 0, 0.35);

	    .day & {
	        background: transparent; //rgba(63, 63, 63, 0.33);
	    }

	    .flipster__item--current & {
	        overflow-y: auto;
	    }
	}

  .screenpanel-footer,
  [data-earththeme-screenpanel-footer] {
	    border-radius: 0em 0em 1em 1em;

	    top: auto;
	    bottom: 0;
	    height: 2em;
	    min-height: 2em;
	}


	background: rgba(0, 0, 0, 0.66);
    .day & {
        background: rgba(63, 63, 63, 0.66);
    }

    .intro & {
        background: none transparent;
	      background-color: transparent;
	      border: 0 none;
	      box-shadow: none;
    }
}

.ui-coverflow {
    width: 100vw !important;
    left: 0px !important;
    /*perspective-origin: 100vw 45% !important;*/
    perspective-origin: center !important;
}

.flipster--coverflow {

    overflow-x: visible;
    overflow-y: visible;

    .flipster__item__content {
	    @include vendor('box-reflect', 'unset');

	    box-reflect: unset !important;
	    -webkit-box-reflect: unset !important;
	}

	.flipster__item--past {
	    opacity: 0.5;
	}

	.flipster__item {

	     &:first-child + .flipster__item,
	     &:first-child + .flipster__item + .flipster__item {
	         button.wm-previous {
	             display: none;
	         }
	     }

	     &:last-child {
	         button.wm-next {
	             display: none;
	         }

	     }
	}

}
