
.button {

	@include contentBox;
	
	background-color: rgba(127, 191, 255, 0.25);
	border-radius: 1.5em;
    opacity: 1;
    color: $white;
    
    &:hover {
		background-color: rgba(127, 191, 255, 0.5);
    }
    
    
    &.secondary {
		background-color: rgba(222, 255, 255, 0.33);
		
		&:hover {
			background-color: rgba(222, 255, 255, 0.45);
		}
	}
    
    &.success {
		background-color: rgba(31, 255, 127, 0.33);
		
		&:hover {
			background-color: rgba(31, 255, 127, 0.66);
		}
	}
    
    &.warning {
		background-color: rgba(255, 255, 127, 0.5);
		
		&:hover {
			background-color: rgba(255, 255, 0, 0.66);
		}
	}
    
    &.alert {
		background-color: rgba(255, 31, 0, 0.33);
		
		&:hover {
			background-color: rgba(255, 31, 0, 0.66);
		}
	}
}

