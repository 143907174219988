

body,
p, label,
input, select, textarea {
    color: $white;
}

/* Wide */
@include breakpoint(wide) {
}

/* Normal */
@include breakpoint(normal) {
}

/* Mobile */
@include breakpoint(mobile) {
}

/* Mobile (Portrait) */
@include breakpoint(mobilep) {

}

