.search {
	input,
	input:focus,
	[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
	[type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus {
	    padding-left: 0.5em;
	    padding-right: 0.5em;
	    font-size: 0.9rem;
	    padding-top: 0;
	    padding-bottom: 0;
	    height: 37px;
	}
	.input-group {
	    position: relative;
	    top: 0.25em;
	}
	.input-group-label {
	    height: 37px;
	}

	.input-group-field {
	    width: calc(100vw - 20em);
	    //min-width: 50px;
	    //max-width: 250px;
	}
}
