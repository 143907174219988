

/* some element defaults */



/* Wide */
@include breakpoint(wide) {

	/* BG */
		
		//@include bg(_misc(bg-width));

}

/* Normal */
@include breakpoint(normal) {

	/* BG */

		//@include bg(_misc(bg-width) * 0.5);

}

/* Mobile */
@include breakpoint(mobile) {

	/* Basic */

	/* BG */

		//@include bg(_misc(bg-width) * 0.2);

}

/* Mobile (Portrait) */
@include breakpoint(mobilep) {

	/* BG */

		//@include bg(_misc(bg-width) * 0.275);

}

