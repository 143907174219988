input,
input:focus,
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
[type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
select,
select:focus,
textarea,
textarea:focus,
button,
button:focus {
    @include contentBox;
	border-radius: 1.5em;
	color: $white;
    
    .input-group > & ~ :first-child,
    .input-group > &:first-child {
	    border-radius: 1.5em 0 0 1.5em;
	    box-shadow: -2px 0px 9px 2px rgba(127, 191, 255, 0.3);
	}
	
	.input-group > & ~ :last-child,
	.input-group > &:last-child {
	    border-radius: 0 1.5em 1.5em 0;
	    border-left-width: 0;
	}
	
	& ~ .input-group-label {
    	@include contentBox;
	    box-shadow: 2px 0px 9px 2px rgba(127, 191, 255, 0.3);
	    background: rgba(127, 191, 255, 0.25);
	    color: $white;
	}

}

.input-group {
	    
	> .input-group-label, 
	> .input-group-button,
	> input, 
	> select, 
	> button {
		border-radius: 1.5em;
	    box-shadow: 2px 0px 9px 2px rgba(127, 191, 255, 0.3);
	    color: $white;
	    
	}
    
	> .input-group-label, 
	> .input-group-button {
	
    	@include contentBox;
	    background: rgba(127, 191, 255, 0.25);
	    
	}
	    
	> :focus {
		box-shadow: 0 0 10px 3px rgba(127,191,255,.5);
	}
	> :not(:focus) {
		box-shadow: 0px 0px 10px 2px rgba(127,191,255,.3);
	}
	
    > :not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    > :not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    > :first-child {
	    border-top-left-radius: 1.5em;
	    border-bottom-left-radius: 1.5em;
	    box-shadow: -2px 0px 9px 2px rgba(127, 191, 255, 0.3);
	    
	}
	
    > :last-child {
	    border-top-right-radius: 1.5em;
	    border-bottom-right-radius: 1.5em;
	    box-shadow: -2px 0px 9px 2px rgba(127, 191, 255, 0.3);
	}

    > input:focus~.input-group-label, 
    > input~.input-group-label {
	    border-radius: 0 0 0 0;
    }

	> :first-child > button, 
	> :last-child > button,
	> :first-child > input, 
	> :last-child > input {
	    background-color: transparent;
	    border: 0 none;
	    box-shadow: none;
	    border-radius: 0 0 0 0;
	}
	
}

	
@import '../atoms/buttons';
@import '../atoms/inputs';
