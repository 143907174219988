// Misc.
  $time: 300s;

	$misc: (
		/* aerial:
		bg:					#348cb2 url("images/bg.jpg") bottom left,
		bgWidth:			1500px */

		/* earth (black): */
		bg:					#348cb2 url("../img/black-world-map.jpg") repeat center center,
		bgWidth:			2400px

		/* earth (day):
		bg:					#348cb2 url("../img/earth_day_scaled.jpg") repeat center center,
		bgWidth:			2400px */

		/* bb:
		bg:					#348cb2 url("../img/wallpaper.jpg") repeat-x center left,
		bgWidth:			11600px */
		// bgWidth:			6842px
		// bgWidth:			13684px
	);

	$bgNight: (
		//bg:					#348cb2 url("../img/black-world-map.jpg") repeat center center,
		bg:					#000000 url("../img/earth_night_scaled.jpg") repeat left center,
		bgWidth:			2400,
		duration:     $time
	);

	$bgDay: (
		bg:					#000000 url("../img/earth_day_scaled.jpg") repeat left center,
		bgWidth:			2400,
		duration:     $time
	);

	$bgIntro: (
    bg:         #000000 url("../img/black-world-map.jpg") repeat-x left center,
    bgWidth:      2400,
		/*bg:					#348cb2 url("../img/wallpaper.jpg") repeat-x center left,
		bgWidth:			11600px,*/
		duration:     $time
	);


// Duration.
	$duration: (
		/* earth: */
		bg:					360s,

		wrapper:			1.5s,
		overlay:			1.0s,
		modalpopup:			0.5s,
		nav-icons:			0.5s
	);

// Size.
	$size: (
		nav-icon-wrapper:	2.75em,
		nav-icon:			1em,
		nav-icon-earth:		1.25em
	);

// Font.
	$font: (
	);

// Palette.
	$palette: (
		bg:					$white, // #fff,
		fg:					$white, // #fff,

		nav-icon: (
			hover-bg:		rgba($white,0.1275), // rgba(255,255,255,0.1275),
			hover-fg:		$white,
			active-bg:		rgba($white,0.35), // rgba(255,255,255,0.35),
			active-fg:		$white
		)
	);
