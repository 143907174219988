@include keyframes('iconspin') {
	0%		{ @include vendor('transform', 'rotateY(0deg)'); }
	50%	    { @include vendor('transform', 'rotateY(360deg)'); }
	100%	{ @include vendor('transform', 'rotateY(720deg)'); }
}

.fa-globe,
.fa-apple-tux,
.fa-bjoernbartels-earth {
    
    font-size: 1.1em;
    
    &:before {
        
	    [data-loading]:not([data-loading=""]) &,
	    .loading & {
	         @include vendor('animation', 'iconspin 3.1415s linear infinite alternate');
		}
		
    }
    
    &:hover {
    	font-size: 1.1em;
    }
    
    &:hover:before {
    	////font-size: 1.1em;
	    border: 1px solid rgba(127, 191, 255, 0.5);
        border-radius: 50%;
	    box-shadow: 0px 0px 5px 3px rgba(127, 191, 255, 0.5);
    }
    
    .top-right-nav & {
    
        margin-left: -3px;
        
	    &:hover {
	    	font-size: 1.1em;
	    }
	    
	    &:hover:before {
	    	font-size: 1.25em;
	    }
	}
	
}

.fa-apple-tux,
.fa-bjoernbartels-earth {

    &:before {

        content: '_';
        background : url('../img/apple-tux/tux-n-tosh.svg') no-repeat 50% 40%;
        background-size: 55%;
        background-color: rgba(0,0,0,.66);
        text-indent: -999em
    
    }

}
