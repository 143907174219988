/**
 * Contenido
 * https://www.contenido.org/
 *
 * CSS overrides and fixes
 *
 * fixes issues regarding broken backend display caused by theme specific (element) styling
 *
 */ 

/* alignment/style editor buttons (edit, save) in editor view */
[class*="CMS_"] ~ a {
     border  : 0 none transparent;  
     float   : left;
     position: relative;
}