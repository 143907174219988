/*$nav-fontsize: 9pt !default;

$nav-fontsize-panel: ($nav-fontsize - 4pt);

$nav-icon-size: 2em;
$nav-icon-margin: 1.25em;
$nav-icon-size-panel: $nav-icon-size * 0.8;

$delay-nav-icons: 0.1s;

*/

@include keyframes('nav-icons') {
	0%		{ 
		@include vendor('transform', 'translate3d(0,1em,0)'); 
		opacity: 0;
		background-color: rgba(0, 0, 0, 0.5);
	}
	100%	{ 
		@include vendor('transform', 'translate3d(0,0,0)'); 
		opacity: 1; 
	}
}

nav {
	margin: 1.5em 0 0 0;
	
	.navigation & {
		margin: 0 0 0 0;
	}	

	ul:not(.breadcrumbs) li {
		@include vendor('animation', 'nav-icons #{_duration(nav-icons)} ease-in-out forwards');
		@include vendor('backface-visibility', 'hidden');
		@include vendor('transform', 'translate3d(0,0,0)');
		display: inline-block;
		height: _size(nav-icon-wrapper);
		line-height: _size(nav-icon-wrapper) * 1.1;
		opacity: 0;
		position: relative;
		top: 0;
		width: _size(nav-icon-wrapper);

		@for $x from 1 through 10 {
			&:nth-child(#{$x}) {
				@include vendor('animation-delay', ($delay-nav-icons + ($x * $delay-nav-icon)) + '');
			}
		}
		
		.search &:first-child {
		    width: auto;
		    
		    & + li {
		        font-size: 0.9em;
		    }
		}
		
	}

}

nav :not(.breadcrumbs) a { 
	
	@include iconBullet;
	

	&:hover {
		font-size: 1.1em;
	}

	&:active {
		font-size: 1.0em;
	}

	span {
		display: none;
	}
}

.earth > nav :not(.breadcrumbs) a:before {
	font-size: _size(nav-icon-earth);
}

.navigation {
	
	z-index     : 99999;
	
	position    : fixed;
	
	.panel {
		position: fixed;
		display : none;

		.icon:hover > .label {
		    
		    @include contentBox;
	        color: lightblue;
	        
		    display: block;
		    position: absolute;
		    left: auto;
		    right: auto;
		}
	
	}
	
	&:hover .panel {
		display : block;
	}
	
	#main.intro ~ & {
		display : none;
	}
	
	
	&.top-right {
		top         : 1.25em;
		right       : 0.875em;
		left        : auto;
		bottom      : auto;

		.panel {
		
			top         : 1.25em;
			right       : _size(nav-icon-wrapper) * 1.1;
			left        : auto;
			bottom      : auto;
			padding-right: 1em;
			
			.icon > .label {
			    top  : _size(nav-icon-wrapper) * 1.25;
			    right: 0;
			}
			
		}
		
		&:nth-of-type(2n+2) {
			top         : _size(nav-icon-wrapper) * 1.875;
			right       : 0.5em;
			
			.panel {
				top         : _size(nav-icon-wrapper) * 1.875;
			}
		}
		
		&:nth-of-type(3n+3) {
			top         : _size(nav-icon-wrapper) * 3;
			right       : 0.5em;
			
			.panel {
				top         : _size(nav-icon-wrapper) * 3;
			}
		}
		
		&:nth-of-type(4n+4) {
			top         : _size(nav-icon-wrapper) * 4.125;
			right       : 0.5em;
			
			.panel {
				top         : _size(nav-icon-wrapper) * 4.125;
			}
		}
		
	}
	
	&.top-left {
		top         : 1.25em;
		right       : auto;
		left        : 0.5em;
		bottom      : auto;
		
		.panel {
			top         : 1.25em;
			bottom      : auto;
			right       : auto;
			left        : _size(nav-icon-wrapper) * 1.1;
			padding-left: 1em;
			
			.icon > .label {
			    top  : _size(nav-icon-wrapper) * 1.25;
			    left: 0;
			}
		}
		
		&:nth-of-type(2n+2) {
			top         : _size(nav-icon-wrapper) * 1.55;
			left        : 0.5em;
			
			.panel {
				top         : _size(nav-icon-wrapper) * 1.55;
			}
		}
		
		&:nth-of-type(3n+3) {
			top         : _size(nav-icon-wrapper) * 2.725;
			left        : 0.5em;
			
			.panel {
				top         : _size(nav-icon-wrapper) * 2.725;
			}
		}
		
		&:nth-of-type(4n+4) {
			top         : _size(nav-icon-wrapper) * 3.85;
			left        : 0.5em;
			
			.panel {
				top         : _size(nav-icon-wrapper) * 3.85;
			}
		}

	}
	
	&.bottom-left {
		top         : auto;
		right       : auto;
		left        : 0.5em;
		bottom      : 1.25em;
		
		.panel {
			top         : auto;
			bottom      : 1.25em;
			right       : auto;
			left        : _size(nav-icon-wrapper) * 1.1;
			padding-left: 1em;
			
			.icon > .label {
			    top  : _size(nav-icon-wrapper) * -0.8;
			    left: 0;
			}
		}
		
		&:nth-of-type(2n+2) {
			bottom      : _size(nav-icon-wrapper) * 1.55;
			left        : 0.5em;
			
			.panel {
				bottom         : _size(nav-icon-wrapper) * 1.55;
			}
		}
		
		&:nth-of-type(3n+3) {
			bottom      : _size(nav-icon-wrapper) * 2.725;
			left        : 0.5em;
			
			.panel {
				bottom         : _size(nav-icon-wrapper) * 2.725;
			}
		}
		
		&:nth-of-type(4n+4) {
			bottom      : _size(nav-icon-wrapper) * 3.85;
			left        : 0.5em;
			
			.panel {
				bottom         : _size(nav-icon-wrapper) * 3.85;
			}
		}

	}
	
	&.bottom-right {
		top         : auto;
		right       : 0.5em;
		left        : auto;
		bottom      : 1.25em;
		
		.panel {
			top         : auto;
			bottom      : 1.25em;
			right       : _size(nav-icon-wrapper) * 1.1;
			left        : auto;
			padding-right: 1em;
			
			.icon > .label {
			    top  : _size(nav-icon-wrapper) * -0.8;
			    right: 0;
			}
		}
		
		&:nth-of-type(2n+2) {
			bottom         : _size(nav-icon-wrapper) * 1.55;
			right          : 0.5em;
			
			.panel {
				bottom         : _size(nav-icon-wrapper) * 1.55;
			}
		}
		
		&:nth-of-type(3n+3) {
			bottom         : _size(nav-icon-wrapper) * 2.725;
			right          : 0.5em;
			
			.panel {
				bottom         : _size(nav-icon-wrapper) * 2.725;
			}
		}
		
		&:nth-of-type(4n+4) {
			bottom         : _size(nav-icon-wrapper) * 3.85;
			right          : 0.5em;
			
			.panel {
				bottom         : _size(nav-icon-wrapper) * 3.85;
			}
		}
	}
	
}


/* Wide */

	@include breakpoint(wide) {


		/* navigation */

			.navigation {
	    	}

	}

/* Normal */

	@include breakpoint(normal) {


		/* navigation */

			.navigation {
	    	}
	    
	}

/* Mobile */

	@include breakpoint(mobile) {

		/* navigation */

			.navigation {
			
				h1 {
					font-size: 2.5em;
				}

				p {
					font-size: 1em;
				}

				nav {
					font-size: 1em;

					a {
						&:hover {
							font-size: 1em;
						}

						&:active {
							font-size: 1em;
						}
					}
				}
				
			}

	}

/* Mobile (Portrait) */

	@include breakpoint(mobilep) {

		/* navigation */

			.navigation {
			
				nav {
					padding: 0 1em;
				}
				
			}

	}