/**
    Example usage:
    
	a {
	  color: gray;
	  @include transition(color .3s ease);
	  &:hover {
	    color: black;
	  }
	}
	
 */
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}