/*
    Example usage: 
    
    @include opacity(0.8);
    
 */
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}