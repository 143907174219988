/**
 * WordPress
 * https://wordpress.com
 *
 * CSS overrides and fixes
 *
 * fixes issues regarding broken backend display caused by theme specific (element) styling
 *
 */ 

