/* base and intro theme */


:root {
  --height-ratio: calc(100vh / 1500);
}


@import 'libs/mixins';
//@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700,800,900");
//@import url("font-awesome.min.css");
@import url("ventus.css");

/*
	Aerial by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

	@import "libs/skel";

	@include skel-breakpoints((
		wide: '(max-width: 1600px)',
		normal: '(max-width: 1280px)',
		mobile: '(max-width: 736px)',
		mobilep: '(max-width: 480px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border'
	));

	$delay-wrapper:			_duration(wrapper) - 1s;
	$delay-overlay:			$delay-wrapper - 0.5s;
	$delay-modalpopup:		$delay-overlay + _duration(overlay) - 0.75s;
	$delay-nav-icons:		$delay-modalpopup + _duration(modalpopup) - 1s;
	$delay-nav-icon:		0.125s;

/* Basic */

	body, input, select, textarea {
		color: _palette(fg);
		font-family: 'Source Sans Pro', Verdana, Helvetica, Arial, sans-serif;
		font-size: 15pt;
		font-weight: 300 !important;
		letter-spacing: -0.025em;
		line-height: 1.75em;
	}

	body {
		background: _palette(bg);
		background-color: #000;
		overflow: hidden;

		&.loading{
			* {
				@include vendor('animation', 'none !important');
			}
		}

		&.styleguide {
			overflow-y: auto;
		}
	}

	a {
		@include vendor('transition', 'border-color 0.2s ease-in-out');
		border-bottom: dotted 1px;
		color: inherit;
		outline: 0;
		text-decoration: none;

		&:hover {
			border-color: transparent;
		}
	}

/* Icon */

	.icon {
		@include icon;
		position: relative;

		> .label {
			display: none;
		}
	}

/* Wrapper */

	@include keyframes('wrapper') {
		0%		{ opacity: 0; }
		100%	{ opacity: 1; }
	}

	[data-earththeme-ui] {
		@include vendor('animation', 'wrapper #{_duration(wrapper)} forwards');
		height: 100%;
		left: 0;
		opacity: 0;
		position: fixed;
		top: 0;
		width: 100%;
	}

/* BG */

  $width: val($bgIntro, bgWidth) * 3;
/*@mixin bg($width) {
  @include keyframes('bg') {
    0%		{ @include vendor('transform', 'translate3d(0, 0, 0)'); }
    100%	{ @include vendor('transform', 'translate3d( calc( #{$width * -1} * var(--height-ratio) ), 0, 0)'); }
  }

  #bg {
    background-size: auto 100%;
    width: calc( calc(#{$width * 1} * var(--height-ratio) ) + (100vw * 1.1) );
    opacity: 0.5;
  }

}*/

	@mixin bgDayFrames($width) {
		@include keyframes('bgDayFrames') {
			/*0%	    { @include vendor('transform', 'translate3d(#{$width * -2},0,0)'); }
			100%    { @include vendor('transform', 'translate3d(0,0,0)'); }*/
      0%	{ @include vendor('transform', 'translate3d( calc( #{$width * -2} * var(--height-ratio) ), 0, 0)'); }
      100%		{ @include vendor('transform', 'translate3d(0, 0, 0)'); }
		}
	}

	@mixin bgNightFrames($width) {
		@include keyframes('bgNightFrames') {
			/*0%	    { @include vendor('transform', 'translate3d(#{$width * -2},0,0)'); }
			100%    { @include vendor('transform', 'translate3d(0,0,0)'); }*/
      0%	{ @include vendor('transform', 'translate3d( calc( #{$width * -2} * var(--height-ratio) ), 0, 0)'); }
      100%		{ @include vendor('transform', 'translate3d(0, 0, 0)'); }
		}
	}

	@mixin bgIntroFrames($width) {
		@include keyframes('bgIntroFrames') {
			/*0%		{ @include vendor('transform', 'translate3d(0,0,0)'); }
			100%	{ @include vendor('transform', 'translate3d(#{$width * -2},0,0)'); }*/
      0%	{ @include vendor('transform', 'translate3d( calc( #{$width * -2} * var(--height-ratio) ), 0, 0)'); }
      100%		{ @include vendor('transform', 'translate3d(0, 0, 0)'); }
		}
	}

	[data-earththeme-background] {
		@include vendor('transition', 'background 3s linear, width 3s linear');

		@include vendor('animation', 'bgNightFrames #{val($bgNight, duration)} linear infinite');
		@include vendor('backface-visibility', 'hidden');
		@include vendor('transform', 'translate3d(0,0,0)');

		/* Set your background with this */
		background: val($bgNight, bg);

		background-repeat: repeat-x;
		background-size: auto 100%;
		opacity: 1;
		position: fixed;
		height: 100%;
		left: 0;
		top: 0;

    width: calc( calc(#{$width * 1} * var(--height-ratio) ) + (100vw * 1.1) );
		//width: (val($bgIntro, bgWidth) * 3);
    @include bgNightFrames(val($bgNight, bgWidth) * 1);


    &.bgNight,
    .night & {
			//@include vendor('animation', 'bgNightFrames #{val($bgNight, duration)} linear infinite');
			@include vendor('backface-visibility', 'hidden');
			//@include vendor('transform', 'translate3d(0,0,0)');

			/* Set your background with this */
			background: val($bgNight, bg);

			background-size: auto 100%;
			//width: (val($bgNight, bgWidth) * 3);
			@include bgNightFrames(val($bgNight, bgWidth) * 1);
    }

    &.bgDay,
    .day & {
			//@include vendor('animation', 'bgDayFrames #{val($bgDay, duration)} linear infinite');
			@include vendor('backface-visibility', 'hidden');
			//@include vendor('transform', 'translate3d(0,0,0)');

			/* Set your background with this */
			background: val($bgDay, bg);

			background-size: auto 100%;
			//width: (val($bgDay, bgWidth) * 3);
			@include bgDayFrames(val($bgDay, bgWidth) * 1);
    }

    &.bgIntro,
    .intro & {
			@include vendor('animation-name', 'bgIntroFrames');
			@include vendor('animation-duration', '#{val($bgIntro, duration)}');
			//@include vendor('animation', 'bgIntroFrames #{val($bgIntro, duration)} linear infinite !important');
			@include vendor('backface-visibility', 'hidden');
			//@include vendor('transform', 'translate3d(0,0,0)');

			/* Set your background with this */
			background: val($bgIntro, bg);

			background-size: auto 100%;
			//width: (val($bgIntro, bgWidth) * 3);
      //width: calc( calc(#{val($bgIntro, bgWidth) * 1} * var(--height-ratio) ) + (100vw * 1.1) );
      //width: calc( (#{$width * 1} * var(--height-ratio) ) + (100vw * 1.1) );
			@include bgIntroFrames(val($bgIntro, bgWidth) * 1.5);
    }
	}

/* Overlay */

	@include keyframes('overlay') {
		0%		{ opacity: 0; }
		100%	{ opacity: 1; }
	}

	#overlay {
		@include vendor('animation', 'overlay #{_duration(overlay)} #{$delay-overlay} forwards');
		background-attachment: fixed, fixed;
		background-image: url('images/overlay-pattern.png'), url('images/overlay.svg');
		background-position: top left, center center;
		background-repeat: repeat, no-repeat;
		background-size: auto, cover;
		height: 100%;
		left: 0;
		opacity: 0;
		position: fixed;
		top: 0;
		width: 100%;
	}

/* Main */

	[data-earththeme-mainscreen] {
		height: 100%;
		left: 0;
		position: absolute;
		//text-align: center;
		top: 0;
		width: 100%;
        padding-top: 5%; //4.75em;
        padding-bottom: 0em;
        padding-left: 0;
        padding-right: 0;

		/*&:before {
			content: '';
			display: inline-block;
			height: 100%;
			margin-right: 0;
			vertical-align: middle;
			width: 1px;
		}
		&.intro:before,
		&.ui-coverflow-wrapper:before {
			display: none;
		}*/
	}

/* introScreen */

	@include keyframes('modalpopup') {
		0%		{ @include vendor('transform', 'translate3d(0,1em,0)'); opacity: 0; }
		100%	{ @include vendor('transform', 'translate3d(0,0,0)'); opacity: 1; }
	}


	#introScreen {

		@include vendor('animation', 'modalpopup #{_duration(modalpopup)} #{$delay-modalpopup} forwards');
		@include vendor('backface-visibility', 'hidden');
		@include vendor('transform', 'translate3d(0,0,0)');


	}

	#introScreen {
		//cursor: default;
		//display: inline-block;
		//opacity: 0;
		//position: relative;
		text-align: center;
		//top: -1em;
		vertical-align: middle;
		width: 100%;

		h1 {
			font-size: 4.35em;
			font-weight: 900;
			letter-spacing: -0.035em;
			line-height: 1em;
		}

		p {
			font-size: 1.25em;
			margin: 0.75em 0 0.25em 0;
			opacity: 0.75;
		}

	}

/* Footer */

	[data-earththeme-footerscreen] {
		font-size: 8pt;
		//@include vendor('background-image', 'linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,0.5) 75%)');
		background: none transparent;
		bottom: 0;
		cursor: default;
		height: 2em;
		left: 0;
		line-height: 1em;
		position: absolute;
		text-align: center;
		width: 100%;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}


/* Wide */

	@include breakpoint(wide) {

		/* Basic */

			body, input, select, textarea {
				font-size: 13pt;
			}

		/* BG */

            [data-earththeme-background] {
			    //@include bg(_misc(bg-width));
            }
	}

/* Normal */

	@include breakpoint(normal) {

		/* Basic */

			body, input, select, textarea {
				font-size: 12pt;
			}

		/* BG */

            [data-earththeme-background] {
			    //@include bg(_misc(bg-width) * 0.5);
            }

	}

/* Mobile */

	@include breakpoint(mobile) {

		/* Basic */

			body {
				min-width: 320px;
			}

			body, input, select, textarea {
				font-size: 11pt;
			}

		/* BG */

            [data-earththeme-background] {
			    //@include bg(_misc(bg-width) * 0.2);
            }

		/* introScreen */

			#introScreen {
				h1 {
					font-size: 2.5em;
				}

				p {
					font-size: 1em;
				}

				nav {
					font-size: 1em;

					a {
						&:hover {
							font-size: 1em;
						}

						&:active {
							font-size: 1em;
						}
					}
				}
			}

	}

/* Mobile (Portrait) */

	@include breakpoint(mobilep) {

		/* BG */

            [data-earththeme-background] {
    			//@include bg(_misc(bg-width) * 0.275);
            }

		/* introScreen */

			#introScreen {
				nav {
					// padding: 0 1em;
				}
			}

	}
