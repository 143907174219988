/*
    Example usage: 
    
    @include animation(10s, 5s, keyframe_animation_name)
    
 */
@mixin animation ($delay, $duration, $animation) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;
    -o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: forwards; /* this prevents the animation from restarting! */
}


/*
    Example usage: 
    
    @include keyframes(slide-down) {
	  0% { opacity: 1; }
	  90% { opacity: 0; }
	}
	
	.element {
	  width: 100px;
	  height: 100px;
	  background: black;
	  @include animationPlain('slide-down 5s 3');
	}
    
 */
@mixin animationPlain ($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}