$intro-nav-fontsize: $nav-fontsize;

.intro {

	#introScreen {
	
		//@include contentBox;
		
	    width: 100%;
	    padding-top: 5%;
	    
	    ul {
	        margin-left: 0;
	    }
	}
	
	@include breakpoint(mobile) {
	    #introScreen {
	    	//width: 75%;
	    }
	}
	
	@include breakpoint(mobilep) {
	    #introScreen {
		    //width: 95%;
		    padding-top: 20%;
		    padding-bottom: 20%;
	    }
	}
}

/* Wide */
	
	@include breakpoint(wide) {
		
	.intro {
			/* styles */
				
				// @include bg(_misc(bg-width));
	
				#introScreen {
					h1 {
						font-size: 2.5em;
					}
	
					p {
						font-size: $intro-nav-fontsize;
					}
	
					nav {
						font-size: $intro-nav-fontsize;
						a {
							&:hover {
								font-size: $intro-nav-fontsize * 1.1;
							}
	
							&:active {
								font-size: $intro-nav-fontsize * 1.1;
							}
						}
					}
				}
	
		}
	}

/* Normal */
	@include breakpoint(normal) {
		
	.intro {
			/* styles */
	
				// @include bg(_misc(bg-width) * 0.5);
	
				#introScreen {
					h1 {
						font-size: 2.5em;
					}
	
					p {
						font-size: 1em;
					}
	
					nav {
						font-size: $intro-nav-fontsize;
	
						a {
							&:hover {
								font-size: $intro-nav-fontsize;
							}
	
							&:active {
								font-size: $intro-nav-fontsize;
							}
						}
					}
				}
	
		}
	}

/* Mobile */	
	@include breakpoint(mobile) {
	
	.intro {
			/* Header */
	
				#introScreen {
					h1 {
						font-size: 2.5em;
					}
	
					p {
						font-size: 1em;
					}
	
					nav {
						font-size: $intro-nav-fontsize;
	
						a {
							&:hover {
								font-size: $intro-nav-fontsize;
							}
	
							&:active {
								font-size: $intro-nav-fontsize;
							}
						}
					}
				}
	
		}

	}

/* Mobile (Portrait) */
	@include breakpoint(mobilep) {
		
	.intro {
			/* Header */
	
				#introScreen {
					nav {
						padding: 0 $intro-nav-fontsize;
					}
				}
	
		}
	
	}