#main.earth {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100%; /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
}

#main.earth > svg#globe {
    display: block;
    position: relative;
    //top: 0px;
    left: auto;
    //bottom: 0px;
    right: auto;
    margin-left: auto;
    margin-right: auto;
}